<template>
	<div class="pd30">
		<a-spin :spinning="loading">
			<div class="flex alcenter space">
				<div class="ft20 cl-black cl-main ftw500">{{ getTitle }}</div>
				<div>
					<a-button @click="$router.go(-1)">返回</a-button>
				</div>
			</div>

			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<!-- <a-form-model-item required label="选择商品">
							<div style="display: flex;">
								<a-button type='primary' @click="chooseShop">选择商品</a-button>
								<a-input type="text" v-model="goods.name" readOnly style="width: 200px;"/>
							</div>
						</a-form-model-item> -->
						<a-form-model-item required label="类型">
							<a-radio-group v-model="form.type">
								<a-radio :value="1">首页</a-radio>
								<a-radio :value="2">商城</a-radio>
							</a-radio-group>
						</a-form-model-item>

						<!-- <a-form-model-item required label="地址">
							<a-input  v-model="form.url"/>
						</a-form-model-item> -->

						<a-form-model-item required label="上传图片">
							<div class="ft12 ftw400 cl-notice">建议上传240x240px，最多上传9张</div>
							<div class="mt10 ml20">
								<upload-list v-model="form.photos" :size="9"></upload-list>
							</div>
						</a-form-model-item>

						<!-- <a-form-model-item required label="文字内容">
							<a-textarea  v-model="form.info" :rows="11" />
						</a-form-model-item> -->

						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
							<a-button type="primary" @click="onSubmit" :loading="confirmLoading">
								保存发布
							</a-button>
						</a-form-model-item>
					</a-form-model>
				</div>
			</div>

		</a-spin>
		<!-- <a-modal v-model="choose" title="选择商品" @ok="handleOk" width="70%">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="商品名称">
						<a-input v-model="search.name" placeholder="请输入商品名称"></a-input>
					</a-form-item>
		
					<a-form-item label="销量">
						<a-input v-model="search.bg_take_count" style="width: 150px"></a-input>
						-
						<a-input v-model="search.end_take_count" style="width: 150px"></a-input>
					</a-form-item>
					
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
					</a-form-item>
				</a-form>
			</div>
			
			<a-table
				:columns="columns"
				row-key="goods_id"
				:data-source="datas"
				:pagination="pagination"
				:loading="loading"
				@change="handleTableChange"
			>
				<template slot="action" slot-scope="record">
					<a-button type="primary" @click="selectShop(record)">选择商品</a-button>
				</template>

</a-table>


</a-modal> -->
	</div>
</template>

<script>
import uploadList from '@/components/upload/list.vue';




export default {
	components: {
		uploadList,
	},
	data() {
		return {
			loading: false,
			confirmLoading: false,
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
			look_id: 0,
			form: {
				photos: [],
				// info:'',
				type: '',
				// url: '',
			},
			choose: false,
			search: {
				name: '',
				bg_take_count: '',
				end_take_count: '',
				status: 1,
			},
			columns: [
				// {title: '商品编号',dataIndex: 'goods_id',align: 'center',ellipsis: true},
				// {title: '商品',dataIndex: 'name',align: 'center',ellipsis: true},
				// {title: '商品',key: 'goods',align: 'center',scopedSlots: {customRender: 'goods'}},
				// {title: '原价',dataIndex: 'original_price',align: 'center',scopedSlots: {customRender: 'original_price'}},
				// {title: '会员价',dataIndex: 'vip_price',align: 'center',scopedSlots: {customRender: 'vip_price'}},
				{ title: '销量', dataIndex: 'take_count', align: 'center', ellipsis: true },
				{ title: '库存', dataIndex: 'quota', align: 'center', ellipsis: true },
				// {title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
				// {title: '排序',dataIndex: 'sort',align: 'center',ellipsis: true},
				{ title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
			],
			datas: [],
			pagination: {
				current: 1,
				pageSize: 10, //每页中显示10条数据
				total: 0,
			},
			// goods:{
			// 	name: '',
			// 	goods_id: ''
			// }
		}
	},
	created() {
		if (!this.$route.query.id) {
			this.look_id = 0;
		} else {
			this.look_id = parseInt(this.$route.query.id);
		}
		this.loaddata();
	},
	computed: {
		getTitle() {
			if (this.look_id == 0) {
				return '添加相册';
			} else {
				return '编辑相册';
			}
		}
	},
	methods: {
		// selectShop(item){
		// 	console.log(item);
		// 	this.goods.name = item.name
		// 	this.goods.goods_id = item.goods_id
		// 	this.choose = false
		// },
		// getLists(){
		// 	if(this.loading==true) return;
		// 	this.loading=true;
		// 	this.$http.api('admin/getGoodsList',{
		// 		limit:this.pagination.pageSize,
		// 		page:this.pagination.current,
		// 		name:this.search.name,
		// 		bg_take_count:this.search.bg_take_count,
		// 		end_take_count:this.search.end_take_count,
		// 		status:this.search.status,
		// 	}).then(res=>{
		// 		this.pagination.total=res.total;
		// 		this.datas=res.list;
		// 		this.loading=false;
		// 	}).catch(res=>{
		// 		console.log(res);
		// 		this.loading=false;
		// 	})
		// },

		chooseShop() {
			this.choose = true
		},
		loaddata() {
			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('admin/showLook', {
				look_id: this.look_id
			}).then(res => {
				this.loading = false;
				if (res.detail) {
					this.form = res.detail;
					// console.log(this.form, '	this.form ');
				}
			}).catch(res => {
				console.log(res);
				this.loading = false;
				this.$router.go(-1);
			})
		},

		onSubmit() {
			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('admin/saveLook', {
				look_id: this.look_id,
				photos: this.form.photos,
				// info:this.form.info,
				// url:this.form.url,
				// goods_ids: this.goods.goods_id,
				type: this.form.type,
			}).then(res => {
				this.$message.success('保存成功');
				this.$router.go(-1);
				this.loading = false;
			}).catch(res => {
				console.log(res);
				this.loading = false;
			})
		},
		handleOk() {

		},
		// searchAct(){
		// 	this.getLists()
		// },
		// handleTableChange(pagination, filters, sorter) {
		// 	console.log(pagination);
		// 	const pager = { ...this.pagination };
		// 	pager.current = pagination.current;
		// 	this.pagination = pager;
		// 	this.getLists()
		// },
	}
}
</script>

<style></style>
